export const convertDataShop = (dataShop) => {
    const { start_guide, app_status } = dataShop
    const start_guide_process = {
        text: '0/4',
        percentage: 0    }
    if (start_guide) {
        start_guide.process = {
            ...start_guide.process,
            enable_extension: app_status
        }

        const all_steps = Object.keys(start_guide.process).length
        const steps_completed = Object.values(start_guide.process)?.reduce((acc, step) => acc + step)
        start_guide_process.text = `${steps_completed}/${all_steps}`
        start_guide_process.percentage = Math.ceil(steps_completed / all_steps * 100)
    }

    return {
        shop: dataShop,
        start_guide_process,
        app_status
    }
}