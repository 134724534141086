import { createSlice } from "@reduxjs/toolkit"
import { createBlock, deleteBlock, getAllBlockList, updateBlock, updateStatus } from "./blockLists.thunk"

export const BlockListSlice = createSlice({
  name: "block_list",
  initialState: {
    success: false,
    message: "",
    blockLists: [],
    type: "create",
    effect_loading: {
      block_all: false,
      action: false,
      delete: false
    },
    blockEdit: {
      status: false,
      email: "",
      name: "",
      phone: "",
      shipping_address: ""
    }
  },
  reducers: {
    setDataEdit: (state, action) => {
      state.blockEdit = action.payload
    },
    setType: (state, action) => {
      state.type = action.payload
    },
    setBlockLists: (state, action) => {
      state.blockLists = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllBlockList.fulfilled, (state, action) => {
        state.blockLists = action.payload.data.data?.reverse()
        state.effect_loading.block_all = false
      })
      .addCase(getAllBlockList.pending, (state) => {
        state.effect_loading.block_all = true
      })
      .addCase(createBlock.pending, (state) => {
        state.effect_loading.action = true
      })
      .addCase(createBlock.fulfilled, (state, action) => {
        state.blockLists.unshift(action.payload.data.data)
        state.effect_loading.action = false
      })
      .addCase(updateBlock.pending, (state) => {
        state.effect_loading.action = true
      })
      .addCase(updateBlock.fulfilled, (state, action) => {
        const data = action.payload.data?.data
        state.blockLists = state.blockLists.map((block) => (block.id === data.id ? data : block))
        state.blockEdit = {}
        state.effect_loading.action = false
      })
      .addCase(updateStatus.fulfilled, (state, action) => {
        const data = action.payload.data?.data
        state.blockLists = state.blockLists.map((block) => (block.id === data.id ? data : block))
      })
      .addCase(deleteBlock.pending, (state) => {
        state.effect_loading.delete = true
      })
      .addCase(deleteBlock.fulfilled, (state, action) => {
        const blockDelete = action.payload.data?.data
        state.blockLists = state.blockLists.filter((block) => block.id !== blockDelete)
        state.effect_loading.delete = false
      })
  }
})
export const { setDataEdit, setType, setBlockLists } = BlockListSlice.actions

export default BlockListSlice.reducer
