import { createSlice } from "@reduxjs/toolkit"
import { assignUser, getUsers, syncUsers } from "./crispUser.thunk"

export const CrispUserSlice = createSlice({
  name: "crisp_stats",
  initialState: {
    success: false,
    message: "",
    users: [],
    params: {},
    effect_loading: {
      block_all: false,
      sync: false,
      assign: false
    }
  },
  reducers: {
    setUsers: (state, action) => {
      state.users = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUsers.fulfilled, (state, action) => {
        state.users = action.payload.data.data
        state.effect_loading.block_all = false
      })
      .addCase(getUsers.pending, (state) => {
        state.effect_loading.block_all = true
      })
      .addCase(syncUsers.pending, (state) => {
        state.effect_loading.sync = true
      })
      .addCase(syncUsers.fulfilled, (state) => {
        state.effect_loading.sync = false
      })
      .addCase(assignUser.pending, (state) => {
        state.effect_loading.assign = true
      })
      .addCase(assignUser.fulfilled, (state) => {
        state.effect_loading.assign = false
      })
  }
})
export const { setUsers } = CrispUserSlice.actions

export default CrispUserSlice.reducer
