// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

// ** Service Imports
import { convertSettingsToSave } from './Settings.thunks'

const api = `${process.env.REACT_APP_API_URL}/api`
const settingAPI = `${api}/settings`
const enableAppAPI = `${api}/enable-theme-app-extension`
const downgradeAPI = `${api}/downgrade-app`

export const getSettings = createAsyncThunk('setting/getSetting', async params => {
    const response = await axios.get(`${settingAPI}`, { params })
    return { params, data: response.data }
})

export const saveSettings = createAsyncThunk('setting/saveSettings', async (settings) => {
    const settingsSave = convertSettingsToSave(settings)
    const response = await axios.post(`${settingAPI}`, settingsSave)
    return { data: response.data }
})

export const enableApp = createAsyncThunk('setting/enable-app', async (data) => {
    const response = await axios.post(`${enableAppAPI}`, data)
    return { data: response.data }
})

export const downgrade = createAsyncThunk('setting/downgrade', async (data) => {
    const response = await axios.post(`${downgradeAPI}`, data)
    return { data: response.data }
})

export const settingSlice = createSlice({
    name: 'settings_shop',
    initialState: {
        params: {},
        success: false,
        message: '',
        settings: {},
        func_trigger: null,
        settings_format: {},
        settings_change: {},
        settings_has_change: false,
        status_save_settings: false,
        shop: {},
        app_status: false,
        effects_loading: {
            block_get_settings: true
        }
    },
    reducers: {
        changeEffects: (state, action) => {
            const { payload } = action
            for (const key in payload) {
                state.effects_loading[key] = payload[key]
            }
        },
        resetSettings: (state) => {
            state.settings = { ...state.settings }
        },
        changeSettings: (state, action) => {
            const settings_tmp = JSON.parse(action.payload)
            if (!settings_tmp.compare_gift_price_with_product?.status) {
                delete settings_tmp.compare_gift_price_with_product?.value
            }
            if (settings_tmp.quantity_management !== "sync_quantity") {
                settings_tmp.sync_quantity = 'stop_offer'
            }

            const settingsString = JSON.stringify(settings_tmp)
            if (state.settings_format && Object.entries(state.settings_format).length > 0) {
                state.settings_has_change = state.settings_format !== settingsString
            } else {
                state.settings_format = settingsString
            }
            state.settings_change = JSON.parse(action.payload) 
        },
        setSaveSettings: (state, action) => {
            state.status_save_settings = action.payload
        },
        setFuncTrigger: (state, action) => {
            state.func_trigger = action.payload
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getSettings.fulfilled, (state, action) => {
                state.params = action.payload.params
                if (action.payload.data.success) {
                    state.settings = action.payload.data.data ?? {}
                    state.settings_format = {}
                    state.settings_has_change = false
                }

                state.effects_loading.block_get_settings = false
                state.success = action.payload.data.success
                state.message = action.payload.data.message
                state.shop = action.payload.data.shop
            })
            .addCase(getSettings.rejected, (state) => {
                state.effects_loading.block_get_settings = false
            })
            .addCase(saveSettings.fulfilled, (state, action) => {
                if (action.payload.data.success) {
                    state.settings = action.payload.data.data ?? {}
                    state.settings_format = {}
                    state.settings_has_change = false
                }

                state.status_save_settings = false
                state.success = action.payload.data.success
                state.message = action.payload.data.message
                state.shop = action.payload.data.shop
            })
            .addCase(saveSettings.rejected, (state) => {
                state.status_save_settings = false
            })
            .addCase(enableApp.fulfilled, (state, action) => {
                if (action.payload.data.success) {
                    state.shop = action.payload.data.shop ?? {}
                    state.app_status = action.payload.data.data?.app_status ?? false
                }
            })
    }
})

export const { changeEffects, resetSettings, changeSettings, setSaveSettings, setFuncTrigger } = settingSlice.actions
export default settingSlice.reducer
