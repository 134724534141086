import axios from "axios"
import { toast } from "react-toastify"

const onSuccess = (res) => {
  return {
    type: "success",
    data: res.data
  }
}
const onError = (err) => {
  const unknownError = {
    error: "Internal Server Error",
    message: "Something went wrong",
    statusCode: 500
  }
  return {
    type: "error",
    data: err.response.data || unknownError
  }
}

const onRequestError = (err) => {
  const unknownError = {
    error: "Internal Server Error",
    message: "Something went wrong",
    statusCode: 500
  }
  if (err.response.status === 401) {
    window.location.replace("/login")
    toast.error("Login session has expired!")
  }
  return {
    type: "error",
    data: err.response.data || unknownError
  }
}

const request = axios.create()
const publicRequest = axios.create()

// Configure axios

request.interceptors.response.use(onSuccess, onRequestError)
publicRequest.interceptors.response.use(onSuccess, onError)

request.interceptors.request.use((config) => {
  const localTokens = localStorage.getItem("access_token")
  if (localTokens !== null) {
    config.headers.Authorization = `Bearer ${localTokens}`
  } else {
    window.location.replace("/login")
  }
  return config
})

export { request, publicRequest }
