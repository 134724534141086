import { createAsyncThunk } from "@reduxjs/toolkit"

import { request } from "../../../utility/toolRequest"

const api = `${process.env.REACT_APP_API_URL}/api/tools`
const variableAPI = `${api}/variable`

export const getVariable = createAsyncThunk("variable/getVariable", async (params) => {
  const response = await request.get(`${variableAPI}`, { params })
  return response
})

export const saveVariable = createAsyncThunk("variable/saveVariable", async (handle_settings) => {
  const response = await request.put(`${variableAPI}`, handle_settings)
  return response
})