import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

import { request } from "../../../utility/toolRequest"

const api = `${process.env.REACT_APP_API_URL}/api/tools`


export const handleGetStatisticShops = createAsyncThunk("statisticShops/handleGetStatisticShops", async () => {
  const response = await request.get(`${api}/ab-testing`)
  return response.data
})

export const handleGetStatisticShopsByTime = createAsyncThunk("statisticShops/handleGetStatisticShopsByTime", async (data) => {
  const response = await request.post(`${api}/ab-testing-by-time`, data)
  return response.data
})


const statisticSlice = createSlice({
  name: "statistics",
  initialState: {
    AbTesting: [],
    shopsPopupAndCharged: [],
    shopsNoPopupAndCharged:[]
  },
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(handleGetStatisticShops.fulfilled, (state, action) => {
        state.AbTesting = action.payload.data.data
        state.shopsPopupAndCharged = action.payload.data.shops_popup
        state.shopsNoPopupAndCharged = action.payload.data.shops_no_popup
      })
      .addCase(handleGetStatisticShopsByTime.fulfilled, (state, action) => {
        state.AbTesting = action.payload.data.data
        state.shopsPopupAndCharged = action.payload.data.shops_popup
        state.shopsNoPopupAndCharged = action.payload.data.shops_no_popup
      })
  }
})

export default statisticSlice.reducer
