import { createAsyncThunk } from "@reduxjs/toolkit"
import { request } from "../../../utility/toolRequest"

const api = `${process.env.REACT_APP_API_URL}/api/tools`
const blockApi = `${api}/block-list`
const updateBlockApi = `${api}/update-status-block`

export const getAllBlockList = createAsyncThunk("blockList/getAll", async (params) => {
  const response = await request.get(blockApi, { params })
  return response
})

export const createBlock = createAsyncThunk("blockList/create", async (data) => {
  const response = await request.post(blockApi, data)
  return response
})

export const updateBlock = createAsyncThunk("blockList/update", async (data) => {
  const response = await request.put(`${blockApi}/${data.id}`, data)
  return response
})

export const showBlock = createAsyncThunk("blockList/show", async (id) => {
  const response = await request.get(`${blockApi}/${id}`)
  return response
})

export const deleteBlock = createAsyncThunk("blockList/delete", async (id) => {
  const response = await request.delete(`${blockApi}/${id}`)
  return response
})

export const updateStatus = createAsyncThunk("block/updateStatus", async (data) => {
  const response = await request.put(`${updateBlockApi}/${data.id}`, { status: !data.status })
  return response
})
