import { createAsyncThunk } from "@reduxjs/toolkit"
import { request } from "../../../utility/toolRequest"

const api = `${process.env.REACT_APP_API_URL}/api/tools`

export const handleCreateRecommendedApp = createAsyncThunk("recommendedApps/handleCreateRecommendedApp", async (data) => {
  const response = await request.post(`${api}/recommended-apps`, data)
  return response
})

export const handleUpdateRecommendedApp = createAsyncThunk("recommendedApps/handleUpdateRecommendedApp", async (data) => {
  const response = await request.put(`${api}/recommended-apps/${data.id}`, data)
  return response
})

export const handleDeleteRecommendedApp = createAsyncThunk("recommendedApps/handleDeleteRecommendedApp", async (id) => {
  const response = await request.delete(`${api}/recommended-apps/${id}`)
  return response
})

export const handleGetAllRecommendedApp = createAsyncThunk("recommendedApps/handleGetAllRecommendedApp", async () => {
  const response = await request.get(`${api}/recommended-apps`)
  return response.data
})