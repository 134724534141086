import { createSlice } from "@reduxjs/toolkit"
import { getStats } from "./crispStat.thunk"

export const CrispStatSlice = createSlice({
  name: "crisp_stats",
  initialState: {
    success: false,
    message: "",
    stats: [],
    params: {},
    effect_loading: {
      block_all: false
    }
  },
  reducers: {
    setStats: (state, action) => {
      state.stats = action.payload
    },
    setParams: (state, action) => {
      state.params = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStats.fulfilled, (state, action) => {
        state.effect_loading.block_all = false
        state.stats = action.payload.data.data?.items
      })
      .addCase(getStats.pending, (state) => {
        state.effect_loading.block_all = true
      })
      .addCase(getStats.rejected, (state) => {
        state.effect_loading.block_all = false
      })
  }
})
export const { setStats, setParams } = CrispStatSlice.actions

export default CrispStatSlice.reducer
