// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

// ** Thunks Imports
import { convertData } from './Offer.thunks'

const api = `${process.env.REACT_APP_API_URL}/api`
const offerApi = `${api}/offers`

export const getOffers = createAsyncThunk('offer/getOffers', async params => {
    const response = await axios.get(`${offerApi}`, { params })
    return { params, data: response.data }
})

export const getOffer = createAsyncThunk('offer/getOffer', async id => {
    const response = await axios.get(`${offerApi}/${id}`)
    return { data: response.data }
})

export const createOffer = createAsyncThunk('offer/createOffer', async (offerData) => {
    delete offerData.id
    delete offerData.delete_ids
    if (offerData.condition_relationship) {
        if (offerData.conditions.length > 1 && offerData.conditions[1].unit) {
            // do something
        } else {
            offerData.conditions = [offerData.conditions[0]]
            offerData.condition_relationship = null
        }
    }
    const response = await axios.post(`${offerApi}`, offerData)
    return { data: response.data }
})

export const updateOffer = createAsyncThunk('offer/updateOffer', async (offerData) => {
    const conditions = {
        update: {},
        delete: [],
        create: []
    }

    if (Array.isArray(offerData.conditions) && offerData.conditions.length) {
        if (offerData.conditions[0].condition_id) {
            conditions.update[offerData.conditions[0].condition_id] = {
                ...offerData.conditions[0]
            }
        } else {
            conditions.create.push(offerData.conditions[0])
        }

        if (offerData.condition_relationship && offerData.conditions.length > 1 && offerData.conditions[1].unit) {
            if (offerData.conditions[1].condition_id) {
                conditions.update[offerData.conditions[1].condition_id] = {
                    ...offerData.conditions[1]
                }
            } else {
                conditions.create.push(offerData.conditions[1])
            }

        }
    }

    if (Array.isArray(offerData.delete_ids) && offerData.delete_ids.length) {
        conditions.delete = offerData.delete_ids.filter(val => !isNaN(parseInt(val)))
    }

    offerData.conditions = conditions

    const response = await axios.put(`${offerApi}/${offerData.id}`, offerData)
    return { data: response.data }
})

export const updateOfferStatus = createAsyncThunk('offer/updateOfferStatus', async (offerData) => {
    const response = await axios.put(`${offerApi}/update-status/${offerData.id}`, offerData)
    return { data: response.data }
})

export const deleteOffer = createAsyncThunk('offer/deleteOffer', async (id) => {
    const response = await axios.delete(`${offerApi}/${id}`)
    return { id, data: response.data }
})

export const activeOffers = createAsyncThunk('offer/activeOffer', async () => {
    const response = await axios.post(`${api}/active-offers`, {})
    return { data: response.data }
})

export const offerSlice = createSlice({
    name: 'offer',
    initialState: {
        params: {
            search: '',
            page: 1,
            per_page: 6,
            last_page: 1
        },
        success: false,
        message: '',
        offers: [],
        offer_detail: {},
        prev_offer_edit: {},
        offer_edit: {
            info: {},
            condition: {},
            gift: {}
        },
        status_save_offer: {
            is_save: false,
            is_apply: false
        },
        status_modal_main_condition: false,
        shop: {},
        effects_loading: {},
        active_view: 'list' // type views [grid, list]
    },
    reducers: {
        changeEffects: (state, action) => {
            state.effects_loading = action.payload
        },
        setActiveView: (state, action) => {
            state.active_view = action.payload
        },
        updateOfferData: (state, action) => {
            state.prev_offer_edit = JSON.parse(JSON.stringify(state.offer_edit))
            state.offer_edit = JSON.parse(JSON.stringify(convertData(action.payload)))
        },
        setStatusSaveOffer: (state, action) => {
            state.status_save_offer = {
                ...action.payload
            }
        },
        setStatusModalMainCondition: (state, action) => {
            state.status_modal_main_condition = action.payload
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getOffers.fulfilled, (state, action) => {
                if (action.payload.data.success) {
                    state.offers = action.payload.data.data?.items ?? []
                    state.params = {
                        ...state.params,
                        ...action.payload.params,
                        page: action.payload.data.data?.current_page ?? 1,
                        last_page: action.payload.data.data?.last_page ?? 1
                    }
                }
                state.success = action.payload.data.success
                state.message = action.payload.data.message
                state.shop = action.payload.data.shop
                state.offer_detail = {}
                state.offer_edit = {}
                state.status_save_offer = {
                    is_save: false,
                    is_apply: false
                }
            })
            .addCase(getOffer.fulfilled, (state, action) => {
                state.offer_detail = action.payload.data.success ? action.payload.data.data ?? {} : {}
                state.offer_edit = {}
                state.success = action.payload.data.success
                state.message = action.payload.data.message
                state.shop = action.payload.data.shop
                state.status_save_offer = {
                    is_save: false,
                    is_apply: false
                }
                state.effects_loading.block_get_offer = false
            })
            .addCase(updateOffer.fulfilled, (state, action) => {
                if (action.payload.data.success) {
                    const offer = action.payload.data.data
                    if (state.offers && offer) {
                        const id = offer.id
                        const index = state.offers.findIndex(item => item.id === id)
                        if (index > -1) {
                            state.offers[index] = offer
                        }
                    }
                }
                state.success = action.payload.data.success
                state.message = action.payload.data.message
                state.shop = action.payload.data.shop
                state.offer_detail = {}
                state.offer_edit = {}
            })
            .addCase(updateOfferStatus.fulfilled, (state, action) => {
                if (action.payload.data.success) {
                    const offer = action.payload.data.data
                    if (state.offers && offer) {
                        const id = offer.id
                        const index = state.offers.findIndex(item => item.id === id)
                        if (index > -1) {
                            state.offers[index] = offer
                        }
                    }
                }
                state.success = action.payload.data.success
                state.message = action.payload.data.message
                state.shop = action.payload.data.shop
                state.offer_detail = {}
                state.offer_edit = {}
            })
            .addCase(deleteOffer.fulfilled, (state, action) => {
                if (action.payload.data.success) {
                    state.offers = state.offers.filter(item => item.id !== action.payload.id)
                }
                state.success = action.payload.data.success
                state.message = action.payload.data.message
                state.shop = action.payload.data.shop
                state.offer_detail = {}
                state.offer_edit = {}
            })
            .addCase(activeOffers.fulfilled, (state, action) => {
                state.success = action.payload.data.success
                state.message = action.payload.data.message
                state.shop = action.payload.data.shop
                state.offer_detail = {}
                state.offer_edit = {}
            })
    }
})

export const { changeEffects, setActiveView, updateOfferData, setStatusSaveOffer, setStatusModalMainCondition } = offerSlice.actions
export default offerSlice.reducer
