import { createAsyncThunk } from "@reduxjs/toolkit"
import { request } from "../../../utility/toolRequest"
import { convertData } from "./utils"

const api = `${process.env.REACT_APP_API_URL}/api/tools`
const shiftApi = `${api}/crisp/shifts`

export const getShifts = createAsyncThunk("crisp/getShifts", async (params) => {
  const response = await request.get(`${shiftApi}`, { params })
  return response
})

export const createShift = createAsyncThunk("crisp/create", async (data) => {
  const response = await request.post(shiftApi, convertData(data))
  return response
})

export const updateShift = createAsyncThunk("crisp/update", async (data) => {
  const response = await request.put(`${shiftApi}/${data.id}`, convertData(data))
  return response
})

export const showShift = createAsyncThunk("crisp/show", async (id) => {
  const response = await request.get(`${shiftApi}/${id}`)
  return response
})

export const deleteShift = createAsyncThunk("crisp/delete", async (id) => {
  const response = await request.delete(`${shiftApi}/${id}`)
  return response
})
