import { showDate, empty, currencyFormat } from '@utils'
import { METRICS } from './Analytic.constant'

export const convertDateParams = (arrayDate) => {
    try {
        return {
            start_date: showDate(arrayDate[0]),
            end_date: showDate(arrayDate[1])
        }
    } catch (e) {
        return {}
    }
}

export const convertDataGraphOrder = (data) => {
    const categories = []
    const total_sales = []
    const number_of_orders = []
    try {
        data?.forEach(item => {
            categories.push(item.date)
            total_sales.push(item.total_sales)
            number_of_orders.push(item.number_of_orders)
        })
    } catch (e) {
        console.error(e)
    }


    return {
        categories, total_sales, number_of_orders
    }
}

export const mergeData = (data_graph, data) => {
    try {
        const indexDate = data_graph?.categories?.indexOf(data.date)
        console.log(indexDate)
        if (indexDate < 0) {
            return data_graph
        }
        data_graph.total_sales[indexDate] = data.total_sales
        data_graph.number_of_orders[indexDate] = data.number_of_orders

        return data_graph
    } catch (e) {
        return data_graph
    }
}

export const mergeMetrics = (metrics, data) => {
    try {
        metrics.total_order -= 1
        metrics.total_sales -= data.total_price
        metrics.total_order = metrics.total_order > 0 ? metrics.total_order : 0
        metrics.total_sales = metrics.total_sales > 0 ? metrics.total_sales : 0
        metrics.average_order_value = metrics.total_order > 0 ? metrics.total_sales / metrics.total_order : 0

        return metrics
    } catch (e) {
        return metrics
    }
}

export const convertMetrics = (metrics, { currency, check_hook_order }) => {
    const rs = []
    for (const key in metrics) {
        if (empty(METRICS[key])) { continue }
        const metric = check_hook_order ? (metrics[key] ?? 0) : 0
        const data = key !== 'total_order' ? currencyFormat(currency, metric, true) : metric
        rs.push({
            ...METRICS[key],
            stats: data,
            renderStats: (<h2 className={'fw-bold'}>{data}</h2>)
        })
    }

    return rs.sort((a, b) => a.priority - b.priority)
}