import { createSlice } from "@reduxjs/toolkit"
import { createAsyncThunk } from "@reduxjs/toolkit"


// ** Axios Imports
import { request } from "../../../utility/toolRequest"

const api = `${process.env.REACT_APP_API_URL}/api/tools`
const syncAPI = `${api}/convert-offers-v2`

export const getShopInfo = createAsyncThunk("handle/syncAPIGet", async (params) => {
  const response = await request.get(`${syncAPI}`, { params })
  return response
})

export const syncReq = createAsyncThunk("handle/syncAPIPost", async (params) => {
  const response = await request.post(`${syncAPI}`, { ...params })
  return response
})

export const HandleSyncSlice = createSlice({
  name: "handle_sync",
  initialState: {
    success: false,
    message: "",
    info: {}
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getShopInfo.fulfilled, (state, action) => {
        if (action.payload.data.success) {
          state.info = action.payload.data.data ?? {}
        }
        state.success = action.payload.data.success
        state.message = action.payload.data.message
      })
      .addCase(syncReq.fulfilled, (state, action) => {
        if (action.payload.data.success) {
          state.info = {...action.payload.data?.data}
        }
        state.success = action.payload.data.success
        state.message = action.payload.data.message
      })
  }
})

export default HandleSyncSlice.reducer
