import { createSlice } from "@reduxjs/toolkit"
import { handleCreateNotification, handleGetAllNotification, handleUpdateNotification } from "./notification.thunk"

const notificationSlice = createSlice({
  name: "notifications",
  initialState: {
    notifications: []
  },
  reducers: {
    deleteNotifications: (state, action) => {
      state.notifications = state.notifications.filter((notification) => notification.id !== action.payload)
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(handleCreateNotification.fulfilled, (state, action) => {
        state.notifications.unshift(action.payload.data.data)
      })
      .addCase(handleGetAllNotification.fulfilled, (state, action) => {
        state.notifications = action.payload.data?.reverse()
      })
      .addCase(handleUpdateNotification.fulfilled, (state, action) => {
        state.notifications = state.notifications.map((obj) => {
          if (obj.id === action.payload.data.data.id) {
            return action.payload.data.data
          }
          return obj
        })
      })
  }
})

export const { deleteNotifications } = notificationSlice.actions
export default notificationSlice.reducer
