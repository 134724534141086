// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

const api = `${process.env.REACT_APP_API_URL}/api`
const fakeAPI = `${api}/test/coupon`

export const getDiscounts = createAsyncThunk('product/getDiscounts', async params => {
    const response = await axios.get(`${fakeAPI}s`, { params })
    return { data: response.data }
})

export const saveDiscount = createAsyncThunk('product/saveDiscount', async (discount) => {
    const response = await axios.post(`${fakeAPI}`, discount)
    return { data: response.data }
})

export const fakeSlice = createSlice({
    name: 'fake_discount',
    initialState: {
        success: false,
        message: '',
        discounts: []
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getDiscounts.fulfilled, (state, action) => {
                if (action.payload.data.success) {
                    state.discounts = action.payload.data.data ?? []
                }
                state.success = action.payload.data.success
                state.message = action.payload.data.message
            })
            .addCase(saveDiscount.fulfilled, (state, action) => {
                if (action.payload.data.success) {
                    const data = action.payload.data.data ?? {}
                    state.discounts.push({
                        ...data,
                        code: data.coupon.code,
                        id: data.discount.id
                    })
                }
                state.success = action.payload.data.success
                state.message = action.payload.data.message
            })
    }
})

export default fakeSlice.reducer
