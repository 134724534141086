// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

const api = `${process.env.REACT_APP_API_URL}/api`
const shopifyPlanAPI = `${api}/plan`
const couponAPI = `${api}/coupon`

export const getShopifyPlan = createAsyncThunk('pricing/getShopifyPlan', async () => {
    const response = await axios.get(`${shopifyPlanAPI}`)
    return { data: response.data }
})

export const subscription = createAsyncThunk('pricing/getCoupon', async (coupon) => {
    const response = await axios.post(`${couponAPI}/${coupon}`)
    return { data: response.data }
})

export const pricingSlice = createSlice({
    name: 'pricing_plan',
    initialState: {
        params: {},
        success: false,
        message: '',
        shop: {},
        shopify_plan: {},
        coupon: {},
        effects_loading: {
            block_get_shopify_plan: true
        }
    },
    reducers: {
        changeEffects: (state, action) => {
            const { payload } = action
            for (const key in payload) {
                state.effects_loading[key] = payload[key]
            }
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getShopifyPlan.fulfilled, (state, action) => {
                if (action.payload.data.success) {
                    state.shopify_plan = action.payload.data.data?.plan ?? {}
                    state.coupon = action.payload.data.data?.coupon
                }

                state.effects_loading.block_get_shopify_plan = false
                state.success = action.payload.data.success
                state.message = action.payload.data.message
                state.shop = action.payload.data.shop
            })
            .addCase(getShopifyPlan.rejected, (state) => {
                state.effects_loading.block_get_shopify_plan = false
            })
            .addCase(subscription.fulfilled, (state, action) => {
                if (action.payload.data.success) {
                    state.coupon = null
                }
            })
    }
})

export const { changeEffects } = pricingSlice.actions
export default pricingSlice.reducer
