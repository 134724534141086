import { createSlice } from "@reduxjs/toolkit"
import { handleCreateBanner, handleGetAllBanner, handleUpdateBanner } from "./banner.thunk"

const bannerslice = createSlice({
  name: "banners",
  initialState: {
    banners: [],
    activeBanners: []
  },
  reducers: {
    deleteBanner: (state, action) => {
      state.banners = state.banners.filter((banner) => banner.id !== action.payload)
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(handleCreateBanner.fulfilled, (state, action) => {
        const bannersArr = [...JSON.parse(JSON.stringify(state.banners)), action.payload.data.data] ?? []
        state.banners = bannersArr?.sort((beforeVal, afterVal) => beforeVal.priority - afterVal.priority) ?? []
      })
      .addCase(handleGetAllBanner.fulfilled, (state, action) => {
        state.banners = action.payload.data?.sort((beforeVal, afterVal) => beforeVal.priority - afterVal.priority) ?? []
        state.activeBanners = action.payload.data?.filter((banner) => banner.status === true).sort((beforeVal, afterVal) => beforeVal.priority - afterVal.priority) ?? []
      })
      .addCase(handleUpdateBanner.fulfilled, (state, action) => {
        state.banners = state.banners.map((obj) => {
          if (obj.id === action.payload.data.data.id) {
            return action.payload.data.data
          }
          return obj
        })
      })
  }
})

export const { deleteBanner } = bannerslice.actions
export default bannerslice.reducer
