import { createAsyncThunk } from "@reduxjs/toolkit"
import { request } from "../../../utility/toolRequest"

const api = `${process.env.REACT_APP_API_URL}/api/tools`

export const handleCreateNotification = createAsyncThunk("notifications/handleCreateNotification", async (data) => {
  const response = await request.post(`${api}/notifications`, data)
  return response
})

export const handleUpdateNotification = createAsyncThunk("notifications/handleUpdateNotification", async (data) => {
  const response = await request.put(`${api}/notifications/${data.id}`, data)
  return response
})

export const handleDeleteNotification = createAsyncThunk("notifications/handleDeleteNotification", async (id) => {
  const response = await request.delete(`${api}/notifications/${id}`)
  return response
})

export const handleGetAllNotification = createAsyncThunk("notifications/handleGetAllNotification", async () => {
  const response = await request.get(`${api}/notifications`)
  return response.data
})