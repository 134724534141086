// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import { convertDataShop } from './Config.thunk'

const api = `${process.env.REACT_APP_API_URL}/api`
const apiShop = `${api}/user`
const apiStartGuide = `${api}/start-guide`
const aiCustomerIO = `${api}/update-customer-io`

export const getShop = createAsyncThunk('config/getShop', async params => {
    const response = await axios.get(`${apiShop}`, { params })
    return { params, data: response.data }
})

export const updateStartGuide = createAsyncThunk('config/updateStartGuide', async data => {
    const response = await axios.put(`${apiStartGuide}`, data)
    return { data: response.data }
})

export const updateCustomerIO = createAsyncThunk('config/updateCustomerIO', async data => {
    const response = await axios.put(`${aiCustomerIO}`, data)
    return { data: response.data }
})

export const configSlice = createSlice({
    name: 'config_theme',
    initialState: {
        array_media: [],
        interval: -1,
        unauthorized: false,
        shop: {},
        app_status: false,
        onboarding_process: 0,
        open_step_priority: null,
        fg_new_create_click: false,
        effects_loading: {
            block_get_shop: true,
            block_update_guide: false
        },
        open_start_guide: {
            dashboard: true,
            tab: false
        },
        start_guide_process: {
            text: '0/4',
            percentage: 0
        }
    },
    reducers: {
        changeEffects: (state, action) => {
            const { payload } = action
            for (const key in payload) {
                state.effects_loading[key] = payload[key]
            }
        },
        setInterval: (state, action) => {
            state.interval = action.payload
        },
        setArrayMedias: (state, action) => {
            state.array_media = [...action.payload]
        },
        setAuthorization: (state, action) => {
            state.unauthorized = action.payload
        },
        setOnboardingProcess: (state, action) => {
            state.onboarding_process = action.payload
        },
        setFinishOnboarding: (state, action) => {
            state.shop.start_guide = {
                ...state.shop.start_guide,
                ...action.payload
            }
            state.onboarding_process = 0
        },
        setOpenStartGuide: (state, action) => {
            const { payload } = action
            for (const key in payload) {
                state.open_start_guide[key] = payload[key]
            }
        },
        setDoneProcessStartGuide: (state, action) => {
            state.shop.start_guide.process = {
                ...state.shop.start_guide.process,
                ...action.payload
            }

            const { shop, start_guide_process } = convertDataShop({
                ...state.shop
            })

            state.shop = { ...shop }
            state.start_guide_process = { ...start_guide_process }
        },
        setStepOpenPriority: (state, action) => {
            state.open_step_priority = action.payload
            state.open_start_guide.tab = true
        }
    },
    extraReducers: {
        [getShop.fulfilled]: (state, action) => {
            if (action.payload.data.success) {
                const { shop, start_guide_process, app_status } = convertDataShop({
                    ...state.shop,
                    ...action.payload.data.shop,
                    ...action.payload.data.data
                })

                state.shop = { ...shop }
                state.start_guide_process = { ...start_guide_process }
                state.app_status = app_status ?? false
                if (!action.payload?.params?.not_check_onboarding) {
                    state.onboarding_process = state.shop?.start_guide?.onboarding ? 0 : 1
                }
            }
            state.effects_loading.block_get_shop = false
            state.effects_loading.block_update_guide = false
        },
        [getShop.rejected]: (state) => {
            state.effects_loading.block_get_shop = false
            state.effects_loading.block_update_guide = false
        },
        [updateStartGuide.fulfilled]: (state, action) => {
            if (action.payload.data.success) {
                const { shop, start_guide_process, app_status } = convertDataShop({
                    ...state.shop,
                    ...action.payload.data.shop,
                    ...action.payload.data.data
                })

                state.shop = { ...shop }
                state.start_guide_process = { ...start_guide_process }
                state.app_status = app_status ?? state.app_status
                state.effects_loading.block_update_guide = false
            }
        },
        [updateStartGuide.rejected]: (state) => {
            state.effects_loading.block_update_guide = false
        },
        [updateCustomerIO.fulfilled]: (state, action) => {
            if (action.payload.data.success) {
                state.fg_new_create_click = true
            }
        }
    }
})

export const {
    setInterval, setArrayMedias, setAuthorization, setOnboardingProcess, setFinishOnboarding, setOpenStartGuide,
    setDoneProcessStartGuide, setStepOpenPriority, changeEffects
} = configSlice.actions
export default configSlice.reducer
