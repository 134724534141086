import axios from 'axios'
import { getSessionToken, isShopifyEmbedded } from "@shopify/app-bridge-utils"
import { Redirect } from "@shopify/app-bridge/actions"
import JwtService from '@src/@core/auth/jwt/jwtService'
import { getShopifyDomain } from "../utils"

export default class JwtServiceOverride extends JwtService {

  constructor(jwtOverrideConfig) {
    super(jwtOverrideConfig)

    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }
    // ** Request Interceptor
    axios.interceptors.request.use(
      async config => {
        const { app } = window
        const token = await getSessionToken(app)
        this.setToken(token)
        const host = window.__SHOPIFY_DEV_HOST
        const shop = getShopifyDomain()

        config.headers.Authorization = `${this.jwtConfig.tokenType} ${token}`
        config.headers['Access-Control-Allow-Origin'] = "*"
        config.params = {
          ...config.params,
          embedded: isShopifyEmbedded() ? 1 : 0,
          shop,
          host
          // ver: 3
        }
        return config
        // // ** Get token from localStorage
        // const accessToken = this.getToken()

        // // ** If token is present add it to request's Authorization Header
        // if (accessToken) {
        //   // ** eslint-disable-next-line no-param-reassign
        //   config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
        // }
        // return config
      },
      error => Promise.reject(error)
    )

    // ** Add request/response interceptor
    axios.interceptors.response.use(
      response => response,
      error => {
        // ** const { config, response: { status } } = error
        const { response } = error
        // const originalRequest = config
        // ** if (status === 401) {
        if (response && response.status === 401) {
          if (!this.isAlreadyFetchingAccessToken) {
            this.isAlreadyFetchingAccessToken = true
            const { app } = window
            const urlParams = new URLSearchParams(location.search)
            urlParams.delete("embedded")
            if (!urlParams.has('shop')) {
              const shop = getShopifyDomain()
              urlParams.set('shop', shop)
            }

            const redirect = Redirect.create(app)
            const urlLogin = `${process.env.REACT_APP_SHOPIFY_AUTH_URL}?${urlParams.toString()}`
            console.log("login", urlLogin)
            redirect.dispatch(
              Redirect.Action.REMOTE,
              urlLogin
            )
          }
        }
        return Promise.reject(error)
      }
    )
  }
}
