export const convertTypeFilter = (data) => {
    const filter = {...data}
    const typeTmp = []
    const vendorTMp = []
    const collectionTmp = []

    if (filter.product_types) {
        for (const [key, value] of Object.entries(filter.product_types)) {
            typeTmp.push({ value: key, label: value })
        }
    }

    if (filter.vendors) {
        for (const [key, value] of Object.entries(filter.vendors)) {
            vendorTMp.push({ value: key, label: value })
        }
    }

    if (filter.collections) {
        for (const [key, value] of Object.entries(filter.collections)) {
            collectionTmp.push({ value: key, label: value })
        }
    }

    return {
        collections: collectionTmp,
        vendors: vendorTMp,
        types: typeTmp
    }
}