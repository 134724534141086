// ** Redux Imports
import { createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import { request } from "../../../utility/toolRequest"

const api = `${process.env.REACT_APP_API_URL}/api/tools`
const handleSettingsAPI = `${api}/handle-config-setting`
const handleHiddenBrandMarkAPI = `${api}/handle-hidden-brand-mark`
const configsAPI = `${api}/configs`
const giftImgSizeAPI = `${api}/handle-gift-image-size`
const customizeForThemeAPI = `${api}/customize-theme`
const creditAI = `${api}/credit-ai`

export const getHandleSettings = createAsyncThunk("handle/getHandleSettings", async (params) => {
  const response = await request.get(`${handleSettingsAPI}`, { params })
  return response
})

export const saveHandleSettings = createAsyncThunk("handle/saveHandleSettings", async (handle_settings) => {
  const response = await request.post(`${handleSettingsAPI}`, handle_settings)
  return response
})

export const getHiddenBrandmark = createAsyncThunk("handle/getHiddenBrandmark", async (params) => {
  const response = await request.get(`${handleHiddenBrandMarkAPI}`, { params })
  return response
})

export const updateHiddenBrandMark = createAsyncThunk("handle/updateHiddenBrandMark", async (params) => {
  const response = await request.put(`${handleHiddenBrandMarkAPI}`, params)
  return response
})

export const saveConfigs = createAsyncThunk("handle/saveConfigs", async (configs) => {
  const response = await request.put(`${configsAPI}`, configs)
  return response
})

export const getConfigs = createAsyncThunk("handle/getConfigs", async (params) => {
  const response = await request.get(`${configsAPI}`, { params })
  return response
})
export const saveGiftImgSize = createAsyncThunk("handle/saveGiftImgSize", async (data) => {
  const response = await request.put(`${giftImgSizeAPI}`, data)
  return response
})

export const getCustomizeForTheme = createAsyncThunk("handle/getCustomizeForTheme", async (params) => {
  const response = await request.get(`${customizeForThemeAPI}`, { params })
  return response
})

export const saveCustomizeForTheme = createAsyncThunk("handle/saveCustomizeForTheme", async (data) => {
  const response = await request.put(`${customizeForThemeAPI}`, data)
  return response
})

export const getCreditAI = createAsyncThunk("handle/getCreditAI", async (params) => {
  const response = await request.get(`${creditAI}`, { params })
  return response
})

export const updateCreditAI = createAsyncThunk("handle/updateCreditAI", async (data) => {
  const response = await request.post(`${creditAI}`, data)
  return response
})
