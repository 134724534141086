import { createSlice } from "@reduxjs/toolkit"
import { handleGetUserInfo, handleLogin, handleLogout, handleRegister } from "./authentication.thunk"
import { empty } from "../../../utility/Utils"

const authenticationSlice = createSlice({
  name: "tools-authentication",
  initialState: {
    userInfo: {}
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(handleRegister.fulfilled, (state, action) => {
        console.log(action.payload)
      })
      .addCase(handleLogin.fulfilled, (state, action) => {
        state.userInfo = action.payload.data.user
        localStorage.setItem("userInfo", JSON.stringify(action.payload.data.user))
      })
      .addCase(handleLogout.fulfilled, () => {
        localStorage.removeItem("userInfo")
        localStorage.removeItem("access_token")
        window.location.replace("/login")
      })
      .addCase(handleGetUserInfo.fulfilled, (state, action) => {
        if (!empty(action.payload.data)) {
          localStorage.setItem("userInfo", JSON.stringify(action.payload.data))
        }
      })
  }
})

export default authenticationSlice.reducer
