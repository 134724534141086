// Settings Services
export const convertSettingsToSave = (data) => {
    const dataSave = { ...data }

    dataSave.compare_gift_price_with_product = data.compare_gift_price_with_product.status ? data.compare_gift_price_with_product.value : false
    if (data.quantity_management === "sync_quantity") {
        dataSave.sync_quantity = data.sync_quantity
        dataSave.manual_input_inventory = false
    } else if (data.quantity_management === "manual_input_inventory") {
        dataSave.sync_quantity = false
        dataSave.manual_input_inventory = true
    } else {
        dataSave.sync_quantity = false
        dataSave.manual_input_inventory = false
    }
    dataSave.gift_format = data.gift_format.value
    dataSave.admin_api_access_token = dataSave.admin_api_access_token ? JSON.parse(data.admin_api_access_token) : ''

    delete dataSave.quantity_management
    return dataSave
}
