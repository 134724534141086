import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { request } from "../../../utility/toolRequest"
const api = `${process.env.REACT_APP_API_URL}/api/tools`

export const getAllShopsWhiteList = createAsyncThunk("shopWhiteList/getAllShopsWhiteList", async () => {
  const response = await request.get(`${api}/shop-white-list`)
  return response
})

export const createShopWhiteList = createAsyncThunk("shopWhiteList/createShopWhiteList", async (data) => {
  const response = await request.post(`${api}/shop-white-list`, data)
  return response
})

export const deleteShopWhiteList = createAsyncThunk("shopWhiteList/deleteShopBadReview", async (data) => {
  const response = await request.post(`${api}/delete-shop-white-list`, data)
  return response
})

export const updateShopIsFreeAllowedCharge = createAsyncThunk("shopWhiteList/updateShopIsFreeAllowedCharge", async (data) => {
  const response = await request.put(`${api}/shops-management/${data.id}`, {
    is_free_allowed: data.is_free_allowed
  })
  return response
})

export const getAllShopsFree = createAsyncThunk("shopWhiteList/getAllShopsFree", async (data) => {
  const perPage = data.perPage ?? 20
  const searchData = data.searchData ?? ""
  const page = data.page ?? 1
  const response = await request.get(`${api}/shops-free?per_page=${perPage}&search=${searchData}&page=${page}`)
  return response
})

const shopWhiteList = createSlice({
  name: "shopWhiteList",
  initialState: {
    whiteList: [],
    gettingShop: false,
    shopsFree: [],
    currentPage: 1,
    lastPage: 1
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllShopsWhiteList.fulfilled, (state, action) => {
        state.whiteList = action.payload.data.data
        state.gettingShop = false
      })
      .addCase(getAllShopsWhiteList.pending, (state) => {
        state.gettingShop = true
      })
      .addCase(createShopWhiteList.fulfilled, (state, action) => {
        if (action.payload.data.data.status === "updated") {
          state.shopsFree = state.shopsFree.filter(item => item?.id !== action.payload.data.data.shop.id)
          state.shopsFree.unshift(action.payload.data.data.shop)
        } else {
          state.whiteList = action.payload.data.data ?? state.whiteList
        }
      })
      .addCase(deleteShopWhiteList.fulfilled, (state, action) => {
        state.whiteList = action.payload.data.data
      })
      .addCase(getAllShopsFree.fulfilled, (state, action) => {
        state.shopsFree = action.payload.data.data.items
        state.currentPage = action.payload.data.data.current_page
        state.lastPage = action.payload.data.data.last_page
        state.gettingShop = false
      })
      .addCase(getAllShopsFree.pending, (state) => {
        state.gettingShop = true
      })
      .addCase(updateShopIsFreeAllowedCharge.fulfilled, (state, action) => {
        state.shopsFree = state.shopsFree.filter((shop) => {
          return shop.id !== action.payload.data.data.id
        })
      })
  }
})

export default shopWhiteList.reducer
