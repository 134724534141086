// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

// ** Service Imports
import { convertDataGraphOrder, convertDateParams, mergeData, mergeMetrics } from './Analytic.thunks'

const api = `${process.env.REACT_APP_API_URL}/api`
const analyticAPI = `${api}/analytics-all-order`
const graphOrderAPi = `${api}/analytics-order-graph`
const orderAPI = `${api}/order`

export const getAnalytics = createAsyncThunk('analytics/getAnalytics', async params => {
    const paramsConvert = {
        ...params,
        // eslint-disable-next-line no-mixed-operators
        ...convertDateParams([new Date(new Date().getTime() - 6 * 24 * 60 * 60 * 1000), new Date()])
    }
    const response = await axios.get(`${analyticAPI}`, { params: paramsConvert })
    return { params, data: response.data }
})

export const getGraphOrder = createAsyncThunk('analytics/getGraphOrder', async params => {
    const paramsConvert = convertDateParams(params?.date_graph)
    const response = await axios.get(`${graphOrderAPi}`, { params: paramsConvert })
    return { params, data: response.data }
})

export const getOrder = createAsyncThunk('analytics/getOrder', async params => {
    const paramsConvert = {
        ...params,
        ...convertDateParams(params?.date_orders)
    }
    delete paramsConvert.date_orders
    delete paramsConvert.date_graph

    const response = await axios.get(`${orderAPI}`, { params: paramsConvert })
    return { params, data: response.data }
})

export const deleteOrder = createAsyncThunk('offer/deleteOrder', async (id) => {
    const response = await axios.delete(`${orderAPI}/${id}`)
    return { id, data: response.data }
})

export const analyticSlice = createSlice({
    name: 'analytics_order',
    initialState: {
        params: {
            search: '',
            page: 1,
            per_page: 6,
            last_page: 1,
            // eslint-disable-next-line no-mixed-operators
            date_orders: [new Date(new Date().getTime() - 6 * 24 * 60 * 60 * 1000), new Date()]
        },
        success: false,
        message: '',
        metrics: {},
        data_graph: {
            categories: [], total_sales: [], number_of_orders: []
        },
        list_order: {},
        shop: {},
        effects_loading: {}
    },
    reducers: {
        changeEffects: (state, action) => {
            state.effects_loading = action.payload
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getAnalytics.fulfilled, (state, action) => {
                if (action.payload.data.success) {
                    state.metrics = action.payload.data.data ?? {}
                    state.data_graph = convertDataGraphOrder(state.metrics?.data_graph ?? [])
                    state.list_order = state.metrics?.list_order ?? {}
                    state.params = {
                        ...state.params,
                        page: state.list_order?.current_page ?? 1,
                        last_page: state.list_order?.last_page ?? 1
                    }
                }
                state.success = action.payload.data.success
                state.message = action.payload.data.message
                state.shop = action.payload.data.shop
            })
            .addCase(getGraphOrder.fulfilled, (state, action) => {
                state.params = {
                    ...state.params,
                    ...action.payload.params
                }

                if (action.payload.data.success) {
                    state.data_graph = convertDataGraphOrder(action.payload.data.data ?? [])
                }

                state.success = action.payload.data.success
                state.message = action.payload.data.message
                state.shop = action.payload.data.shop
            })
            .addCase(getOrder.fulfilled, (state, action) => {
                state.params = {
                    ...state.params,
                    ...action.payload.params
                }
                if (action.payload.data.success) {
                    state.list_order = action.payload.data.data ?? []
                    state.params = {
                        ...state.params,
                        page: state.list_order?.current_page ?? 1,
                        last_page: state.list_order?.last_page ?? 1
                    }
                }
                state.success = action.payload.data.success
                state.message = action.payload.data.message
                state.shop = action.payload.data.shop
            })
            .addCase(deleteOrder.fulfilled, (state, action) => {
                if (action.payload.data.success) {
                    const orderDelete = state.list_order.items?.find(item => item.id === action.payload.id)
                    const { analytic } = action.payload.data.data
                    state.data_graph = mergeData(state.data_graph, analytic)
                    state.metrics = mergeMetrics(state.metrics, orderDelete)
                    state.list_order.items = state.list_order.items?.filter(item => item.id !== action.payload.id)
                }
                state.success = action.payload.data.success
                state.message = action.payload.data.message
                state.shop = action.payload.data.shop
            })
    }
})

export const { changeEffects } = analyticSlice.actions
export default analyticSlice.reducer
