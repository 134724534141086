import { createSlice } from "@reduxjs/toolkit"
import { handleCreateRecommendedApp, handleGetAllRecommendedApp, handleUpdateRecommendedApp } from "./recommendedApp.thunk"

const recommendedAppSlice = createSlice({
  name: "recommendedApps",
  initialState: {
    recommendedApps: []
  },
  reducers: {
    deleteRecommendedApp: (state, action) => {
      state.recommendedApps = state.recommendedApps.filter((recommendedApp) => recommendedApp.id !== action.payload)
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(handleCreateRecommendedApp.fulfilled, (state, action) => {
        const recommendedAppsArr = [...JSON.parse(JSON.stringify(state.recommendedApps)), action.payload.data.data]
        state.recommendedApps = recommendedAppsArr?.sort((beforeVal, afterVal) => beforeVal.priority - afterVal.priority)
      })
      .addCase(handleGetAllRecommendedApp.fulfilled, (state, action) => {
        state.recommendedApps = action.payload.data?.sort((beforeVal, afterVal) => beforeVal.priority - afterVal.priority)
      })
      .addCase(handleUpdateRecommendedApp.fulfilled, (state, action) => {
        state.recommendedApps = state.recommendedApps.map((obj) => {
          if (obj.id === action.payload.data.data.id) {
            return action.payload.data.data
          }
          return obj
        })
      })
  }
})

export const { deleteRecommendedApp } = recommendedAppSlice.actions
export default recommendedAppSlice.reducer
