export const convertDateFromString = (timeString) => {
  if (typeof timeString !== "string") return timeString

  const currentDate = new Date()

  const [hours, minutes, seconds] = timeString.split(":").map(Number)

  currentDate.setHours(hours, minutes, seconds, 0)

  return currentDate
}

export const convertData = (data) => {
  return {
    ...data,
    start_time: new Date(data.start_time).toISOString(),
    end_time: new Date(data.end_time).toISOString()
  }
}

export const uniqueArray = (data) => {
  return data.filter((value, index, array) => array.indexOf(value) === index && value)
}
