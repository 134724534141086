import { createAsyncThunk } from "@reduxjs/toolkit"
import { request } from "../../../utility/toolRequest"

const api = `${process.env.REACT_APP_API_URL}/api/tools`

export const handleCreateBanner = createAsyncThunk("banners/handleCreateBanner", async (data) => {
  const response = await request.post(`${api}/banners`, data)
  return response
})

export const handleUpdateBanner = createAsyncThunk("banners/handleUpdateBanner", async (data) => {
  const response = await request.put(`${api}/banners/${data.id}`, data)
  return response
})

export const handleDeleteBanner = createAsyncThunk("banners/handleDeleteBanner", async (id) => {
  const response = await request.delete(`${api}/banners/${id}`)
  return response
})

export const handleGetAllBanner = createAsyncThunk("banners/handleGetAllBanner", async () => {
  const response = await request.get(`${api}/banners`)
  return response.data
})