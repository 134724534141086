// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

// ** Service Imports
import { convertGiftIconToSave, convertGiftSliderToSave, convertPromotionalMsgToSave } from './Appearance.thunks'
import { imgIconsDefault } from './Appearance.constant'

const api = `${process.env.REACT_APP_API_URL}/api`
const appearanceAPI = `${api}/appearance`

export const getAppearance = createAsyncThunk('setting/getAppearance', async params => {
    const response = await axios.get(`${appearanceAPI}`, { params })
    return { params, data: response.data }
})

export const saveGiftIcon = createAsyncThunk('setting/saveGiftIcon', async ({ appearance, giftIcon, imgIcons }) => {
    const appearanceSave = convertGiftIconToSave(appearance, giftIcon, imgIcons)
    const response = await axios.post(`${appearanceAPI}`, appearanceSave)
    return { data: response.data }
})

export const saveGiftSlider = createAsyncThunk('setting/saveGIftSlider', async ({ appearance, giftSlider }) => {
    const appearanceSave = convertGiftSliderToSave(appearance, giftSlider)
    const response = await axios.post(`${appearanceAPI}`, appearanceSave)
    return { data: response.data }
})

export const savePromotionalMsg = createAsyncThunk('setting/savePromotionalMsg', async ({ appearance, promotionalMsg }) => {
    const appearanceSave = convertPromotionalMsgToSave(appearance, promotionalMsg)
    const response = await axios.post(`${appearanceAPI}`, appearanceSave)
    return { data: response.data }
})

export const appearanceSlice = createSlice({
    name: 'appearance_shop',
    initialState: {
        params: {},
        success: false,
        message: '',
        img_icons_arr: [...imgIconsDefault],
        func_trigger: null,
        appearance_format: {},
        appearance_change: {},
        appearance_has_change: false,
        status_save_appearance: false,
        appearance : {},
        shop: {},
        effects_loading: {}
    },
    reducers: {
        changeEffects: (state, action) => {
            state.effects_loading = action.payload
        },
        resetAppearance: (state) => {
            state.appearance = { ...state.appearance }
        },
        changeAppearance: (state, action) => {
            if (state.appearance_format && Object.entries(state.appearance_format).length > 0) {
                state.appearance_has_change = state.appearance_format !== action.payload
            } else {
                state.appearance_format = action.payload
            }
            state.appearance_change = JSON.parse(action.payload) 
        },
        setSaveAppearance: (state, action) => {
            state.status_save_appearance = action.payload
        },
        setFuncTrigger: (state, action) => {
            state.func_trigger = action.payload
        },
        setImgIcons: (state, action) => {
            state.img_icons_arr = action.payload
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getAppearance.fulfilled, (state, action) => {
                state.params = action.payload.params
                if (action.payload.data.success) {
                    state.appearance = action.payload.data.data ?? {}
                    state.appearance_format = {}
                    state.appearance_has_change = false
                    state.status_save_appearance = false
                }
                state.success = action.payload.data.success
                state.message = action.payload.data.message
                state.shop = action.payload.data.shop
            })
            .addCase(saveGiftIcon.fulfilled, (state, action) => {
                if (action.payload.data.success) {
                    state.appearance = action.payload.data.data ?? {}
                    state.appearance_format = {}
                    state.appearance_has_change = false
                }
                state.success = action.payload.data.success
                state.message = action.payload.data.message
                state.shop = action.payload.data.shop
            })
            .addCase(saveGiftSlider.fulfilled, (state, action) => {
                if (action.payload.data.success) {
                    state.appearance = action.payload.data.data ?? {}
                    state.appearance_format = {}
                    state.appearance_has_change = false
                }
                state.success = action.payload.data.success
                state.message = action.payload.data.message
                state.shop = action.payload.data.shop
            })
            .addCase(savePromotionalMsg.fulfilled, (state, action) => {
                if (action.payload.data.success) {
                    state.appearance = action.payload.data.data ?? {}
                    state.appearance_format = {}
                    state.appearance_has_change = false
                }
                state.success = action.payload.data.success
                state.message = action.payload.data.message
                state.shop = action.payload.data.shop
            })
    }
})

export const { changeEffects, resetAppearance, changeAppearance, setSaveAppearance, setFuncTrigger, setImgIcons } = appearanceSlice.actions
export default appearanceSlice.reducer
