import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

import { request } from "../../../utility/toolRequest"

const api = `${process.env.REACT_APP_API_URL}/api/tools`

export const handleDeleteAdmins = createAsyncThunk("admins/handleDeleteAdmins", async (id) => {
  const response = await request.delete(`${api}/admins/${id}`)
  return response
})

export const handleGetAllAdmins = createAsyncThunk("admins/handleGetAllAdmins", async () => {
  const response = await request.get(`${api}/admins`)
  return response.data
})

export const handleUpdateUserPermissions = createAsyncThunk("admins/handleUpdateUserPermissions", async (data) => {
  const response = await request.put(`${api}/admins/permissions`, data)
  return response
})

const adminSlice = createSlice({
  name: "admins",
  initialState: {
    admins: [],
    gettingAdmin: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(handleGetAllAdmins.fulfilled, (state, action) => {
        state.admins = action.payload.data
        state.gettingAdmin = false
      })
      .addCase(handleGetAllAdmins.pending, (state) => {
        state.gettingAdmin = true
      })
      .addCase(handleDeleteAdmins.fulfilled, (state, action) => {
        state.admins = state.admins.filter((admins) => admins.id !== action.payload.data.data)
      })
      .addCase(handleUpdateUserPermissions.fulfilled, (state, action) => {
        const userUpdate = action.payload.data.data
        state.admins = state.admins.map((user) => {
          if (user.id === userUpdate.id) {
            return {
              ...user,
              permissions: userUpdate.permissions
            }
          }
          return user
        })
      })
  }
})

export default adminSlice.reducer
