import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { request } from "../../../utility/toolRequest"
const api = `${process.env.REACT_APP_API_URL}/api/tools`

export const createShopOneTimeCharge = createAsyncThunk("shopOneTimeCharge/createShopOneTimeCharge", async (data) => {
  const response = await request.post(`${api}/one-time-charge`, data)
  return response
})

export const deleteShopOneTimeCharge = createAsyncThunk("shopOneTimeCharge/deleteShopOneTimeCharge", async (id) => {
  const response = await request.delete(`${api}/one-time-charge/${id}`)
  return response
})

export const getAllShopsOneTimeCharge = createAsyncThunk("shopOneTimeCharge/getAllShopsOneTimeCharge", async (data) => {
  const perPage = data.perPage ?? 20
  const searchData = data.searchData ?? ""
  const page = data.page ?? 1
  const response = await request.get(`${api}/one-time-charge?per_page=${perPage}&search=${searchData}&page=${page}`)
  return response
})

const shopsOneTimeCharge = createSlice({
  name: "shopsOneTimeCharge",
  initialState: {
    gettingShop: false,
    shopsOneTimeCharge: [],
    currentPage: 1,
    lastPage: 1
  },
  reducers: {
    deleteShopOneTimeChargeRdc: (state, action) => {
      state.shopsOneTimeCharge = state.shopsOneTimeCharge.filter((shop) => shop?.id !== action.payload)
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createShopOneTimeCharge.fulfilled, (state, action) => {
        if (action.payload.type === "success") {
          state.shopsOneTimeCharge = state.shopsOneTimeCharge.filter(item => item?.id !== action.payload.data.data.data_charge.id)
          state.shopsOneTimeCharge.unshift({ shop:{shop: action.payload.data.data.shop}, ...action.payload.data.data.data_charge})
        }
      })
      .addCase(getAllShopsOneTimeCharge.fulfilled, (state, action) => {
        state.shopsOneTimeCharge = action.payload.data.data.items
        state.currentPage = action.payload.data.data.current_page
        state.lastPage = action.payload.data.data.last_page
        state.gettingShop = false
      })
      .addCase(getAllShopsOneTimeCharge.pending, (state) => {
        state.gettingShop = true
      })
  }
})

export const { deleteShopOneTimeChargeRdc } = shopsOneTimeCharge.actions
export default shopsOneTimeCharge.reducer
