// ** Images
import fgIconRed from '@src/assets/images/freegifts/fg-icons/fg-icon-red.webp'
import fgIconGreen from '@src/assets/images/freegifts/fg-icons/fg-icon-green.webp'
import fgIconBlue from '@src/assets/images/freegifts/fg-icons/fg-icon-blue.webp'
import fgIconOrange from '@src/assets/images/freegifts/fg-icons/fg-icon-orange.webp'
import fgIconYellow from '@src/assets/images/freegifts/fg-icons/fg-icon-yellow.webp'

// ** Vars
const REACT_APP_API_URL = process.env.REACT_APP_API_URL

export const imgIconsDefault = [
    {
        id: 'images/fg-icons/fg-icon-red.png',
        src: REACT_APP_API_URL ? `${REACT_APP_API_URL}/images/fg-icons/fg-icon-red.png` : fgIconRed
    },
    {
        id: 'images/fg-icons/fg-icon-orange.png',
        src: REACT_APP_API_URL ? `${REACT_APP_API_URL}/images/fg-icons/fg-icon-orange.png` : fgIconOrange
    },
    {
        id: 'images/fg-icons/fg-icon-yellow.png',
        src: REACT_APP_API_URL ? `${REACT_APP_API_URL}/images/fg-icons/fg-icon-yellow.png` : fgIconYellow
    },
    {
        id: 'images/fg-icons/fg-icon-blue.png',
        src: REACT_APP_API_URL ? `${REACT_APP_API_URL}/images/fg-icons/fg-icon-blue.png` : fgIconBlue
    }, 
    {
        id: 'images/fg-icons/fg-icon-green.png',
        src: REACT_APP_API_URL ? `${REACT_APP_API_URL}/images/fg-icons/fg-icon-green.png` : fgIconGreen
    }
]

export const sizeOptions = ['32', '50', '100', '160', '240']