// Images import
import cartQtyImg from '@src/assets/images/freegifts/video/cart-quantity-video.webp'
import cartValueImg from '@src/assets/images/freegifts/video/cart-value-video.webp'
import createOfferImg from '@src/assets/images/freegifts/video/create-offer-video.webp'
import specificProductImg from '@src/assets/images/freegifts/video/specific-product-video.webp'

export const mainOptionsKey = {
    spend_x_amount: {
        key: "spend_x_amount",
        unit: "money"
    },
    purchase_x_products: {
        key: "purchase_x_products",
        unit: "number_of_products"
    },
    purchase_product_x: {
        key: "purchase_product_x",
        unit: "number_of_products"
    }
}

export const subOptionsKey = {
    specific_link_address: "specific-link-address",
    customer_tags: "customer-tags",
    customer_order_history: "narrow-by-customer-order-history"
}

export const videoIntroApp = [
    {
        title: "How to create offers",
        img_src: createOfferImg,
        video_src: "https://www.youtube.com/embed/B_seBM64kdc"
    },
    {
        title: "Cart value condition",
        img_src: cartValueImg,
        video_src: "https://www.youtube.com/embed/OwQcHVwcyJk"
    },
    {
        title: "Cart quantity condition",
        img_src: cartQtyImg,
        video_src: "https://www.youtube.com/embed/kzKjj37BxOc"
    },
    {
        title: "Specific product condition",
        img_src: specificProductImg,
        video_src: "https://www.youtube.com/embed/IAzBXgAt8n0"
    }
]

export const OFFER_STATE = {
    RUNNING: "running",
    NOT_RUNNING: "not_running",
    FAILED: "failed",
    JUST_UPDATED: "just_updated"
}