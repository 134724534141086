// import useJwt from '@src/@core/auth/jwt/useJwt'
import useJwt from '@src/auth/jwt/useJwt'


/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = () => {
  return localStorage.getItem('userData') && localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
}

export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

export const setToken = (token) => useJwt.setToken(token)

export const setUser = (user) => localStorage.setItem('userData', JSON.stringify(user))

export const removeUser = () => localStorage.removeItem('userData')

export const removeToken = () => localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)

/**
 * This function is used for demo purpose route navigation
 * In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 * Please note role field is just for showing purpose it's not used by anything in frontend
 * We are checking role just for ease
 * NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole === 'admin') return '/'
  if (userRole === 'client') return { name: 'access-control' }
  return { name: 'auth-login' }
}

export const getShopifyDomain = () => {
  const urlParams = new URLSearchParams(location.search)

  const { app } = window
  let shop = urlParams.get('shop')
  try {
    const host = atob(window.__SHOPIFY_DEV_HOST)
    if (host?.includes("admin.shopify.com")) {
      const arrDomains = host.split("/")
      const name = arrDomains.slice(-1)[0]
      shop = shop ?? `${name}.myshopify.com`
    } else {
      shop = shop ?? new URL(app.hostOrigin)?.hostname
    }

  } catch (e) { }

  return shop
}