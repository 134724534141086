import { createSlice } from "@reduxjs/toolkit"
import { createShift, deleteShift, getShifts, updateShift } from "./crispShifts.thunk"

export const CrispShiftsSlice = createSlice({
  name: "crisp_shifts",
  initialState: {
    success: false,
    message: "",
    shifts: [],
    type: "create",
    effect_loading: {
      block_all: false,
      action: false,
      delete: false
    },
    shiftEdit: {
      name: "",
      start_time: "",
      end_time: ""
    }
  },
  reducers: {
    setDataEdit: (state, action) => {
      state.shiftEdit = action.payload
    },
    setType: (state, action) => {
      state.type = action.payload
    },
    setShifts: (state, action) => {
      state.shifts = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getShifts.fulfilled, (state, action) => {
        state.shifts = action.payload.data.data?.reverse()
        state.effect_loading.block_all = false
      })
      .addCase(getShifts.pending, (state) => {
        state.effect_loading.block_all = true
      })
      .addCase(createShift.pending, (state) => {
        state.effect_loading.action = true
      })
      .addCase(createShift.fulfilled, (state, action) => {
        state.shifts.unshift(action.payload.data.data)
        state.effect_loading.action = false
      })
      .addCase(updateShift.pending, (state) => {
        state.effect_loading.action = true
      })
      .addCase(updateShift.fulfilled, (state, action) => {
        const data = action.payload.data?.data
        state.shifts = state.shifts.map((shift) => (shift.id === data.id ? data : shift))
        state.shiftEdit = {}
        state.effect_loading.action = false
      })
      .addCase(deleteShift.pending, (state) => {
        state.effect_loading.delete = true
      })
      .addCase(deleteShift.fulfilled, (state, action) => {
        const shiftDelete = action.payload.data?.data
        state.shifts = state.shifts.filter((shift) => shift.id !== shiftDelete)
        state.effect_loading.delete = false
      })
  }
})
export const { setDataEdit, setType, setShifts } = CrispShiftsSlice.actions

export default CrispShiftsSlice.reducer
