import { createSlice } from "@reduxjs/toolkit"
import { createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import { request } from "../../../utility/toolRequest"

const api = `${process.env.REACT_APP_API_URL}/api/tools`
const automationTestAPI = `${api}/automation-test`

export const runAutomationTest = createAsyncThunk("automation-test", async () => {
  const response = await request.get(`${automationTestAPI}`)
  return response
})

export const AutomationTestSlice = createSlice({
  name: "automation_test",
  initialState: {
    success: false,
    message: "",
    url: ""
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(runAutomationTest.fulfilled, (state, action) => {
      state.success = action.payload.data.success
      state.message = action.payload.data.message
      state.url = action.payload.data.url
    })
  }
})

export default AutomationTestSlice.reducer
