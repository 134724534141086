import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { request } from "../../../utility/toolRequest"
const api = `${process.env.REACT_APP_API_URL}/api/tools`
export const getAllShops = createAsyncThunk("shopsManagement/getAllShops", async (data) => {
  const perPage = data.perPage ?? 20
  const searchData = data.searchData ?? ''
  const page = data.page ?? 1
  const response = await request.get(`${api}/shops-management?per_page=${perPage}&search=${searchData}&page=${page}`)
  return response
})
export const updateShopIsFreeAllowedCharge = createAsyncThunk("shopsManagement/updateShopIsFreeAllowedCharge", async (data) => {
  const response = await request.put(`${api}/shops-management/${data.id}`, {
      is_free_allowed: data.is_free_allowed
  })
  return response
})
const shopsManagementSlice = createSlice({
  name: "shopsManagement",
  initialState: {
    shops: [],
    gettingShop: false,
    currentPage: 1,
    lastPage: 1
  },
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllShops.fulfilled, (state, action) => {
        state.shops = action.payload.data.data?.items ?? []
        state.currentPage = action.payload.data.data?.current_page ?? 1
        state.lastPage = action.payload.data.data?.last_page ?? 1
        state.gettingShop = false
      })
      .addCase(getAllShops.pending, (state) => {
        state.gettingShop = true
      })
      .addCase(getAllShops.rejected, (state) => {
        state.gettingShop = false
      })
      .addCase(updateShopIsFreeAllowedCharge.fulfilled, (state, action) => {
        state.shops = state.shops.map((shop) => {
          if (shop.id === action.payload.data.data.id) {
            return {...shop, is_free_allowed: action.payload.data.data.is_free_allowed}
          }
          return shop
        })
      })
  }
})
export default shopsManagementSlice.reducer
