// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

// ** Thunks Imports
import { convertTypeFilter } from './Variants.thunks'

const api = `${process.env.REACT_APP_API_URL}/api`
const productAPI = `${api}/products`
const dataFilterAPI = `${api}/type-filter-product`
const fetchVariantsAPI = `${api}/load-more-search-product`

export const getVariants = createAsyncThunk('product/getVariants', async params => {
    const response = await axios.get(`${productAPI}`, { params })
    return { params, data: response.data }
})

export const searchProducts = createAsyncThunk('product/searchProducts', async params => {
    const response = await axios.get(`${productAPI}`, { params })
    return { params, data: response.data }
})

export const getDataFilter = createAsyncThunk('product/getDataFilter', async params => {
    const response = await axios.get(`${dataFilterAPI}`, { params })
    return { params, data: response.data }
})

export const fetchVariants = createAsyncThunk('product/fetchVariants', async params => {
    const response = await axios.get(`${fetchVariantsAPI}`, { params })
    return { params, data: response.data }
})

export const variantSlice = createSlice({
    name: 'product_shop',
    initialState: {
        params: {},
        success: false,
        message: '',
        products: {
            condition: {},
            gift: {}
        },
        type_filter: {
            types: [],
            vendors: [],
            collections: []
        },
        block_type_filter: {
            condition: {},  // { search: '', collections: false, vendors: false, types: false }
            gift: {}
        },
        data_search: {
            condition: {},
            gift: {}
        },
        fetch_variants: {
            condition: [],
            gift: []
        },
        modal_select: "", // ["condition", "gift"]
        page_info: {
            condition: {},
            gift: {}
        },
        effects_loading: {}
    },
    reducers: {
        changeEffects: (state, action) => {
            state.effects_loading = action.payload
        },
        setBlockTypeFilter: (state, action) => {
            state.block_type_filter[state.modal_select] = {
                ...state.block_type_filter[state.modal_select],
                ...action.payload
            }
        },
        setDataSearch: (state, action) => {
            state.data_search[state.modal_select] = {
                ...state.data_search[state.modal_select],
                ...action.payload
            }
        },
        setTypeModal: (state, action) => {
            state.modal_select = action.payload
        },
        setFetchState: (state, action) => {
            state.fetch_variants[state.modal_select][action.payload.index] = {
                ...action.payload.data
            }
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getVariants.fulfilled, (state, action) => {
                state.params = action.payload.params
                if (action.payload.data.success) {
                    const page_info = action.payload.data.data.data_filter.page_info
                    const products = action.payload.data.data.data_filter.data_all_filter ?? []
                    state.page_info = {
                        condition: { ...page_info },
                        gift: { ...page_info }
                    }
                    state.products = {
                        condition: [...products],
                        gift: [...products]
                    }
                    state.type_filter = convertTypeFilter(action.payload.data.data.type_filter)
                    const fetchStates = products?.map(product => {
                        return ((product.variant?.length ?? 1) < product.total_variants) ? {
                            can_fetch: true,
                            state: false
                        } : false
                    })
                    state.fetch_variants = {
                        condition: [...fetchStates],
                        gift: [...fetchStates]
                    }
                }
                state.success = action.payload.data.success
                state.message = action.payload.data.message
            })
            .addCase(searchProducts.fulfilled, (state, action) => {
                state.params = action.payload.params
                if (action.payload.data.success) {
                    const products = action.payload.data.data.data_filter.data_all_filter ?? []
                    state.page_info[state.modal_select] = action.payload.data.data.data_filter.page_info
                    state.products[state.modal_select] = [...products]
                    state.type_filter = convertTypeFilter(action.payload.data.data.type_filter)
                    const fetchStates = products?.map(product => {
                        return ((product.variant?.length ?? 1) < product.total_variants) ? {
                            is_fetching: false,
                            state: false
                        } : false
                    })
                    state.fetch_variants[state.modal_select] = [...fetchStates]
                }
                state.success = action.payload.data.success
                state.message = action.payload.data.message
            })
            .addCase(getDataFilter.fulfilled, (state, action) => {
                state.params = action.payload.params
                if (action.payload.data.success) {
                    state.type_filter = convertTypeFilter(action.payload.data.data)
                }
                state.success = action.payload.data.success
                state.message = action.payload.data.message
            })
            .addCase(fetchVariants.fulfilled, (state, action) => {
                const { params } = action.payload
                if (action.payload.data.success) {
                    const { data } = action.payload.data
                    const index = state.products[state.modal_select]?.findIndex(item => item.id === params.product_id)
                    if (index >= 0) {
                        const variants = state.products[state.modal_select][index].variant ?? []
                        state.products[state.modal_select][index].variant = [
                            ...variants,
                            ...data.load_more_search_product
                        ]
                    }

                }
                state.success = action.payload.data.success
                state.message = action.payload.data.message
            })
    }
})

export const { changeEffects, setBlockTypeFilter, setTypeModal, setDataSearch, setFetchState } = variantSlice.actions
export default variantSlice.reducer
