import { createSlice } from "@reduxjs/toolkit"
import { getVariable, saveVariable } from "./customVariable.thunk"

export const VariableSlice = createSlice({
  name: "variable",
  initialState: {
    variable: {}
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getVariable.fulfilled, (state, action) => {
        state.variable = action.payload.data.data
      })
      .addCase(saveVariable.fulfilled, (state, action) => {
        state.variable = action.payload.data.data
      })
  }
})

export default VariableSlice.reducer
