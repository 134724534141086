import { createSlice } from "@reduxjs/toolkit"
import {
  getConfigs,
  getCreditAI,
  getCustomizeForTheme,
  getHandleSettings,
  getHiddenBrandmark,
  saveConfigs,
  saveCustomizeForTheme,
  saveGiftImgSize,
  saveHandleSettings,
  updateCreditAI,
  updateHiddenBrandMark
} from "./configSettings.thunk"

export const HandleSettingsSlice = createSlice({
  name: "handle_settings",
  initialState: {
    success: false,
    message: "",
    config: {},
    config_mode: {},
    hidden_brand_mark: false,
    gift_img_size: "",
    customize: {},
    credit_ai: 5
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getHandleSettings.fulfilled, (state, action) => {
        if (action.payload.data.success) {
          state.config = action.payload.data.data ?? {}
        }
        state.success = action.payload.data.success
        state.message = action.payload.data.message
      })
      .addCase(saveHandleSettings.fulfilled, (state, action) => {
        if (action.payload.data.success) {
          state.config = { ...action.payload.data?.data }
        }
        state.success = action.payload.data.success
        state.message = action.payload.data.message
      })
      .addCase(getHiddenBrandmark.fulfilled, (state, action) => {
        state.hidden_brand_mark = action.payload.data.data.hidden_brand_mark
        state.gift_img_size = action.payload.data.data.gift_img_size ?? ""
      })
      .addCase(updateHiddenBrandMark.fulfilled, (state, action) => {
        state.hidden_brand_mark = action.payload.data.data.hidden_brand_mark
      })
      .addCase(getConfigs.fulfilled, (state, action) => {
        state.config_mode = action.payload.data.data ?? {}
      })
      .addCase(saveConfigs.fulfilled, (state, action) => {
        state.config_mode = action.payload.data?.data
      })
      .addCase(saveGiftImgSize.fulfilled, (state, action) => {
        state.gift_img_size = action.payload.data?.data
      })
      .addCase(getCustomizeForTheme.fulfilled, (state, action) => {
        if (action.payload.data.success) {
          state.customize = action.payload.data.data ?? {}
        }
        state.success = action.payload.data.success
        state.message = action.payload.data.message
      })
      .addCase(saveCustomizeForTheme.fulfilled, (state, action) => {
        if (action.payload.data.success) {
          state.customize = { ...action.payload.data?.data }
        }
        state.success = action.payload.data.success
        state.message = action.payload.data.message
      })
      .addCase(getCreditAI.fulfilled, (state, action) => {
        state.credit_ai = action.payload.data?.data ?? 5
      })
      .addCase(updateCreditAI.fulfilled, (state, action) => {
        state.credit_ai = action.payload.data?.data ?? 5
      })
  }
})

export const { setPassword } = HandleSettingsSlice.actions
export default HandleSettingsSlice.reducer
