import { createAsyncThunk } from "@reduxjs/toolkit"
import { request } from "../../../utility/toolRequest"

const api = `${process.env.REACT_APP_API_URL}/api/tools`
const crispApi = `${api}/crisp/users`

export const getUsers = createAsyncThunk("crisp/getUsers", async (params) => {
  const response = await request.get(`${crispApi}`, { params })
  return response
})

export const syncUsers = createAsyncThunk("crisp/syncUsers", async (params) => {
  const response = await request.get(`${crispApi}/sync`, { params })
  return response
})

export const assignUser = createAsyncThunk("crisp/assignUser", async (data) => {
  const response = await request.post(`${crispApi}/assign-shift`, data)
  return response
})
